// @flow

const KEY = 'USER_HAS_SUBSCRIBED';
const storage = typeof localStorage !== 'undefined' ? localStorage : {};

export const setUserHasSubscribed = () => {
  storage[KEY] = '1';
};

export const userHasSubscribed = () => {
  const ret = !!storage[KEY];
  return ret;
};