// @flow
import React from 'react';

import { userHasSubscribed } from '../../utils/subscribe-status';
import SubscribeForm from '../SubscribeForm';
import styles from './SubscribePopup.module.scss';

const hideDateKey = 'SubscribePopup-hide-date';
const HIDE_DURATION = 1000 * 60 * 60 * 24; // 24 hours

type State = $ReadOnly<{|
  visible: boolean,
|}>;

class SubscribePopup extends React.Component<State> {
  state = { visible: false };
  scrollListener: () => void;

  componentDidMount() {
    const hideDateValue = parseInt(localStorage[hideDateKey], 10);
    if (
      userHasSubscribed() ||
      (hideDateValue != null && Date.now() - hideDateValue < HIDE_DURATION)
    ) {
      return;
    }

    this.scrollListener = () => {
      const threshold = Math.min(
        Math.max((document.body ? document.body.offsetHeight : 0) / 3, 1500),
        4500
      );
      if (window.scrollY + window.innerHeight / 2 >= threshold) {
        this.setState({ visible: true });
        window.removeEventListener('scroll', this.scrollListener);
      }
    };

    window.addEventListener('scroll', this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener);
  }

  close = () => {
    this.setState({ visible: false });
    localStorage[hideDateKey] = `${Date.now()}`;
  };

  onKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      this.close();
    }
  };

  render() {
    if (userHasSubscribed()) {
      return null;
    }
    const { visible } = this.state;

    return (
      <div className={`${styles['container']} ${visible ? '' : styles['hidden']}`}>
        <h4 className={styles['title']}>Like what you're reading?</h4>
        <p className={styles['description']}>
          Subscribe to my newsletter <b>Cathode Rey Tube</b> where I write about technology, culture and philosophy!
        </p>
        <SubscribeForm />
        <button className={styles['close']} onClick={this.close}>
          ✕
        </button>
      </div>
    );
  }
}

export default SubscribePopup;