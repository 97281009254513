// @flow strict
import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import NavHeader from '../components/NavHeader';
import Layout from '../components/Layout';
import Post from '../components/Post';
import SubscribePopup from '../components/SubscribePopup';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

const ShareIcons = loadable(() => import('../components/ShareIcons'));

type Props = {
  data: {
    markdownRemark: MarkdownRemark
  }
};

const PostTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { frontmatter } = data.markdownRemark;
  const { title: postTitle, description: postDescription = '' } = frontmatter;
  const metaDescription = postDescription || siteSubtitle;
  const { slug } = data.markdownRemark.fields;

  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    if (hasScrolled) {
      return;
    }

    const listener = () => setHasScrolled(true);
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [hasScrolled, setHasScrolled]);

  return (
    <>
      <NavHeader />
      <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription} >
        <Post post={data.markdownRemark} />
      </Layout>
      <SubscribePopup />
      {hasScrolled && <ShareIcons slug={slug} />}
    </>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      tableOfContents
      frontmatter {
        date
        description
        tags
        title
      }
    }
  }
`;

export default PostTemplate;
