// @flow
import { useSiteMetadata } from '../../hooks';
import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
} from 'react-share';

import styles from './Share.module.scss';

type Props = {
    +slug: string,
    +shareText ?: string,
};

const Share = ({ slug, shareText }: Props) => {
    const twitter = 'reytangular';
    const { url, title } = useSiteMetadata();
    return (
        <div className={styles['share']}>
            <h4 className={styles['share-title']}>{shareText || 'SHARE THIS POST'}</h4>
            <div className={styles['share-buttons']}>
                <FacebookShareButton
                    className={styles['facebook']}
                    resetButtonStyle={false}
                    url={url + slug}
                >
                    Facebook
                </FacebookShareButton>
                <TwitterShareButton
                    className={styles['twitter']}
                    resetButtonStyle={false}
                    url={url + slug}
                    title={title}
                    via={twitter}
                >
                    Twitter
                </TwitterShareButton>
                <LinkedinShareButton
                    className={styles['linkedin']}
                    resetButtonStyle={false}
                    url={url + slug}
                    title={title}
                >
                    LinkedIn
                </LinkedinShareButton>
                <RedditShareButton
                    className={styles['reddit']}
                    resetButtonStyle={false}
                    url={url + slug}
                    title={title}
                >
                    Reddit
                </RedditShareButton>
            </div>
        </div>
    );
}

export default Share;