// @flow
import * as React from 'react';

import styles from './SubscribeForm.module.scss';;

const SubscribeForm = ({ large }: Props) => {
    const height = large ? "320" : "120";
    const displayStyle = { display: 'block' };
    const backgroundStyle = { backgroundColor: 'transparent'};
    return (
        <div className={styles['container']}>
            <iframe src="https://cathodereytube.substack.com/embed" height={height} style={{ ...displayStyle, ...backgroundStyle }} frameborder="0" scrolling="no" />
        </div>
    );
};

export default SubscribeForm;