// @flow
import * as React from 'react';
import Scrollspy from 'react-scrollspy';

import styles from './TableOfContents.module.scss';;

const TableOfContents = ({ toc }: Props) => {
    if (!toc) {
        return null;
    }
    const tocArr = toc.split(/[#"]/);
    const titleArr = toc.split(/[><]/)
    const urls = [];
    const titles = [];

    if (tocArr.length < 3) {
        return null;
    }

    if (titleArr.length < 6) {
        return null;
    }

    for (let i = 2; i < tocArr.length; i = i + 3) {
        urls.push(tocArr[i]);
    }
    for (let i = 6; i < titleArr.length; i = i + 8) {
        titles.push(titleArr[i]);
    }

    if (urls.length !== titles.length) {
        return null;
    }

    return (
        <div className={styles['toc']}>
            <nav>
                <Scrollspy items={urls} currentClassName={styles['toc__tocList-isCurrent']} className={styles['toc__tocList']}>
                    {
                        urls.map((url, index) => (
                            <li className={styles['toc__tocList-notCurrent']} key={`#${url}`}>
                                <a href={`#${url}`}>{titles[index]}</a>
                            </li>
                        ))
                    }
                </Scrollspy>
            </nav>
        </div>
    );
}

export default TableOfContents;